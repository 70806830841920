import React, { useEffect } from "react";
import { playAnimation } from "src/services/animations";
import { changeGroupMaterialsOpacity } from "src/services/materials-textures";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import { Title, Text } from "../dynamic.components";

export const SyringePumps: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  const {
    apiref,
    sidebarStatus,
    animations,
    animationStopped,
    setAnimationStopped,
    activeSubLink,
  } = useStore();

  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "pumps"
    ) {
      activeSubLink.changeMaterials &&
        changeGroupMaterialsOpacity(activeSubLink.changeMaterials[0], 0.1);
      playAnimation();
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "pumps"
    ) {
      activeSubLink.changeMaterials &&
        changeGroupMaterialsOpacity(activeSubLink.changeMaterials[0], 1);
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Continuous flow of running buffer or sample over the sensor chip surface is managed by two high precision syringe pumps, housed inside the instrument." />
      </div>
    </>
  );
};
