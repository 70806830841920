import React from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer } from "../dynamic.components";

export const BufferInlets: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Running buffer and distilled water are supplied from bottles placed on the bench at the right of the instrument via inlet tubings. Smaller bottles (up to 1000mL) may be placed in the accessory holders attached to the holder rail." />
        <Spacer />
        <Text text="Biacore™ 1K+ and Biacore™ 1S+ are equipped with a buffer selector that enables you to change between up to four running buffers without the need for manual intervention, allowing for greater efficiency  to queue activities and methods." />
        <Spacer />
        <Text text="Note! Filter buffers through a 0.22µm filter to avoid introducing biological growth into the flow system." />
      </div>
    </>
  );
};
