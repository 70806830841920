import React, { useEffect } from "react";
import { resetCameraAnnotation } from "src/constants";
import { playAnimationByModel } from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { changeMaterialOpacityByName } from "src/services/materials-textures";
import { useStore } from "src/store";
import { SidebarVisibility, TreeDModel } from "src/types/index.d";
import { Title, Text, Spacer } from "../dynamic.components";

export const SampleHotel: React.FC = (): JSX.Element => {
  const {
    apiref,
    activeModel,
    changeModel,
    sidebarStatus,
    animations,
    animationStopped,
    setAnimationStopped,
    activeSubLink,
    animationIsPlaying,
  } = useStore();

  const showBiacoreOneSPlusLabel = () => {
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[1], 0);
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[0], 1);
  };

  const showBiacoreOneKPlusLabel = () => {
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[0], 0);
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[1], 1);
  };

  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "extras"
    ) {
      playAnimationByModel(activeModel);
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "extras"
    ) {
      setAnimationStopped(true);
      showBiacoreOneSPlusLabel();
    }
  }, [sidebarStatus, animationStopped]);

  useEffect(() => {
    playAnimationByModel(activeModel);
  }, [activeModel]);

  return (
    <>
      <Title title={activeSubLink.text} />
      <div className="c-sidebar__body">
        <Text text="Sample and reagents are placed on a sample tray, either in a microplate or in vials in a reagent rack. Each sample tray can hold one microplate and one reagent rack." />
        <Spacer />
        <Text text="Samples and reagents are placed in a 96- or 384-well standard or deep-well microplate. Reagent rack type A can hold 43 vials, Reagent rack type B can hold 21 vials, compatible with 0.7 to 4.4 mL vials positions. All vials and microplates should be covered with penetrable caps, foil, or septa." />
        <Spacer />
        <Text text="Biacore™&nbsp;1K is equipped with one tray and can hold one microplate and one reagent rack , whereas Biacore™&nbsp;1K+ and Biacore™&nbsp;1S+ are equipped with two trays and can hold two microplates and two reagent racks." />
        <Spacer x={2} />
        <div className="c-sidebar__table-wrapper">
          <table className="c-sidebar__table">
            <thead>
              <tr>
                <th></th>
                <th>Biacore™&nbsp;1K</th>
                <th>Biacore™&nbsp;1K+</th>
                <th>Biacore™&nbsp;1S+</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Number of microplates</th>
                <td>One</td>
                <td>Two</td>
                <td>Two</td>
              </tr>
              <tr>
                <th>Number of reagent racks</th>
                <td>One</td>
                <td>Two</td>
                <td>Two</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Spacer x={3} />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_S_Plus);
            playHotspot(resetCameraAnnotation);
            showBiacoreOneSPlusLabel();
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_S_Plus && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1S+
        </button>
        <Spacer />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_K_Plus);
            playHotspot(resetCameraAnnotation);
            showBiacoreOneKPlusLabel();
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K_Plus && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1K+
        </button>
        <Spacer />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_K);
            playHotspot(resetCameraAnnotation);
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1K
        </button>
      </div>
    </>
  );
};
