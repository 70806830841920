import React from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer, Image, List } from "../dynamic.components";
import expand_beyond_affinity_image from "src/assets/images/expand beyond affinity.jpg";

export const ExpandAffinity: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Biacore™ 1 series is a flexible SPR platform that grows with your project needs. The modular software toolbox approach allows you to expand the application range as your research evolves. Optional Biacore™ Insight add-on software extensions provide application specific functionality and tools to streamline your analysis, improve visualization and reduce time to result." />
        <Spacer x={2} />
        <List
          listArray={[
            "Extended Screening extension – designed for LMW and fragment screening applications.",
            "Concentration & Potency – for reproducible concentration and streamlined drug potency analysis.",
            "Epitope Binning – for epitope identification and control, to maintain unique and diverse epitopes.",
            "Data Integration – for data export in JSON and XML format.",
            "Biacore Intelligent Analysis™ - for evaluation of binding level, and affinity screens using prediction models.",
            "GxP – for work in regulated environments.",
          ]}
        />
        <Spacer />
        <Image
          images={[
            {
              src: expand_beyond_affinity_image,
              alt: "expand beyond affinity",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
