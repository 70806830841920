import React, { useEffect } from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer, Image, CustomLink } from "../dynamic.components";
import reproducibility_1_image from "src/assets/images/reproducibility-1.jpg";
import reproducibility_2_image from "src/assets/images/reproducibility-2.jpg";
import { SidebarVisibility } from "src/types/index.d";
import { playAnimation } from "src/services/animations";

export const Reproducibility: React.FC = (): JSX.Element => {
  const {
    activeSubLink,
    sidebarStatus,
    animationStopped,
    animations,
    apiref,
    setAnimationStopped,
  } = useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "chip-hatch"
    ) {
      playAnimation();
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "chip-hatch"
    ) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={activeSubLink.text} />
      <div className="c-sidebar__body">
        <Text text="Biacore™ 1 series systems operate using the extensive range of Biacore™ Series S sensor chips, which offer support for analysis of a wide range of interactions and molecules." />
        <Spacer />
        <Text text="A variety of already functionalized sensor chips and capture kits offer several options for capturing most common antibodies and molecule tags. This in combination with lab protocols and predefined methods, significantly reduces the time and effort you need to spend on developing your assay." />
        <Spacer />
        <Text text="Ready-made buffers and solutions are available for your convenience, so your assay conditions are kept the same for every experiment." />
        <Spacer />
        <Text text="Resource:" />
        <ul className="c-sidebar__list">
          <li className="c-sidebar__list-item c-sidebar__text">
            <CustomLink
              href="https://www.cytivalifesciences.com/solutions/protein-research/interaction-analysis-with-biacore-surface-plasmon-resonance-spr/biacore-selection-tool"
              text="Biacore™ consumables product guide"
            />
          </li>
          <li className="c-sidebar__list-item c-sidebar__text">
            Biotin CAPture Kit Ready-to-go interaction analysis{" "}
            <CustomLink
              href="https://cdn.cytivalifesciences.com/api/public/content/RMPZCzeRxke2iEQwfpXQOw-pdf"
              text="Download PDF"
              inline={true}
            />
          </li>
        </ul>
        <Spacer x={2} />
        <Image
          images={[
            {
              src: reproducibility_1_image,
              alt: "reproducibility_1_image",
            },
          ]}
        />
        <Image
          images={[
            {
              src: reproducibility_2_image,
              alt: "reproducibility_2_image",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
