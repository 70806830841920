import React from "react";
import { multiModelComponentId, resetCameraAnnotation } from "src/constants";
import {
  playAnimation,
  playAnimationByModel,
  resetAnimationFrame,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { showOneSPlusLabel } from "src/services/materials-textures";
import { useStore } from "../../store";
import { SidebarVisibility, SubLink } from "../../types/index.d";
import SidebarContent from "./Sidebar.content.component";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  const {
    activeSubLink,
    sidebarStatus,
    setSidebarStatus,
    animationIsPlaying,
    activeModel,
    setActiveSubLink,
  } = useStore();
  const closeSidebar = () => {
    setSidebarStatus(SidebarVisibility.notShow);
    resetAnimationFrame();
    playHotspot(resetCameraAnnotation);
    if (activeSubLink.id === "1-1") {
      setTimeout(() => {
        setActiveSubLink({} as SubLink);
      }, 500);
    }
    if (activeSubLink.id === "3-5") {
      showOneSPlusLabel();
    }
  };

  const replayAnimation = () => {
    if (activeSubLink.animations && activeSubLink.animations.length) {
      if (
        activeSubLink.id === "1-1" ||
        activeSubLink.id === "1-2" ||
        activeSubLink.id === "2"
      ) {
        playAnimation();
      }
      if (multiModelComponentId.includes(activeSubLink.id)) {
        playAnimationByModel(activeModel);
      }
    }
  };

  return (
    <div
      className={`c-sidebar ${
        sidebarStatus === SidebarVisibility.show
          ? "show-sidebar-motion"
          : sidebarStatus === SidebarVisibility.notShow && "hide-sidebar-motion"
      }`}
    >
      <>
        <button
          className={`button button--secondary button--xlarge button--thick-border ${
            multiModelComponentId.includes(activeSubLink.id) &&
            animationIsPlaying &&
            "button--disabled"
          }`}
          onClick={closeSidebar}
          disabled={
            multiModelComponentId.includes(activeSubLink.id) &&
            animationIsPlaying
          }
        >
          Return to 3D overview
        </button>
        <SidebarContent />
        {activeSubLink.animations && activeSubLink.animations.length && (
          <button
            className={`button button--secondary button--xlarge button--thick-border ${
              animationIsPlaying ? "button--disabled" : ""
            }`}
            onClick={replayAnimation}
            disabled={animationIsPlaying}
          >
            Replay Animation
          </button>
        )}
      </>
    </div>
  );
};

export default Sidebar;
