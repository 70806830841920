import React from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer, Subtitle } from "../dynamic.components";

export const TemperatureControl: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="SPR response is sensitive to temperature. Precise control of the flow cell temperature is essential for reliable performance." />
        <Spacer />
        <Text text="The flow cell temperature in Biacore™&nbsp;1K and Biacore™&nbsp;1K+, is controlled within the range of 25&#8451; to 37&#8451;, and the sample compartment temperature is controlled within the range 4&#8451; to 37&#8451;." />
        <Spacer />
        <Text text="The flow cell temperature and sample compartment temperature in Biacore™&nbsp;1S+, is controlled within the range of 4&#8451; to 40&#8451;." />
        <Spacer />
        <Text text="The sample hotel is actively maintained at the same temperature as the sample compartment. For optimal temperature regulation, keep the sample hotel door closed, except when handling sample vials or plates, which is possible even during runs." />
        <Spacer x={2} />
        <div className="c-sidebar__table-wrapper">
          <table className="c-sidebar__table">
            <thead>
              <tr>
                <th></th>
                <th>Biacore™&nbsp;1K</th>
                <th>Biacore™&nbsp;1K+</th>
                <th>Biacore&nbsp;1S+</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Analysis/Flow cell temp.</th>
                <td>25&#8451; to 37&#8451;</td>
                <td>25&#8451; to 37&#8451;</td>
                <td>4&#8451; to 40&#8451;</td>
              </tr>
              <tr>
                <th>Sample compartment temp.</th>
                <td>4&#8451; to 37&#8451;</td>
                <td>4&#8451; to 37&#8451;</td>
                <td>4&#8451; to 40&#8451;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
