import React from "react";
import { Title, Text, Spacer, List, Image } from "../dynamic.components";
import { useStore } from "src/store";
import image_1 from "src/assets/images/biacore insight software/first-pic.jpg";
import image_2 from "src/assets/images/biacore insight software/second-pic.jpg";
import image_3 from "src/assets/images/biacore insight software/third-pic.jpg";
import image_4 from "src/assets/images/biacore insight software/fourth-pic.jpg";

export const BiacoreInsightSoftware: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text
          text="Biacore™ Insight Software provides essential functionality for running and evaluating interaction analysis experiments. 
        The flat user interface of the unified Biacore™ Insight Software for Biacore™ 1 series is compatible with Biacore™ 8 series. This makes it easy to switch between different instrument configurations and platforms to minimize time spent on training."
        />
        <Spacer />
        <Text text="The flat user interface of Biacore™ Insight Software for Biacore™ 1 series systems is compatible with Biacore™ 8 series systems." />
        <Spacer />
        <Text text="Biacore™ 1 series stystems can be integrated seamlessly into GxP-regulated workflows. Biacore™ Insight GxP extension provides validated software supporting GLP/GCP/GMP and 21 CFR Part 11 compliance, and includes validation support." />
        <Spacer x={2} />
        <Text text="Note! Connection to Biacore™ Insight database is required to use both the control software and the evaluation software." />
        <Spacer x={2} />
        <ol style={{ listStyle: "upper-roman" }} className="c-sidebar__list">
          <li className="c-sidebar__list-item c-sidebar__text">
            <Text text="Biacore™ Insight Control Software is installed on the instruments controlling computer and controls instrument operation. The control software can be used without a connection to the instrument (or with the instrument switched off) to create analysis methods and to open saved results." />
            <Spacer />
            <Text text="Methods are easily transferred between instrument and labs and minimize development time." />
          </li>
          <Spacer x={2} />

          <li className="c-sidebar__list-item c-sidebar__text">
            <Text
              text="Biacore™ Insight Evaluation Software is a stand-alone software for evaluation of results obtained from Biacore™ 1 series, Biacore™ 8 series, Biacore™ T200 and Biacore™ S200. 
              It is normally installed on the controlling computer to which the instrument is connected, but does not require a connection to the instrument"
            />
            <Spacer />
            <List
              listArray={[
                "Easy export of data and compilation of results",
                "Streamlined, easy to use approach to visualize and analyze kinetic and affinity data with predefined evaluation methods and quality control tools to shorten time to decision",
                "Quickly make figures for reports and presentations or transfer large data sets for aggregation and further analysis",
              ]}
            />
          </li>
          <Spacer x={2} />

          <li className="c-sidebar__list-item c-sidebar__text">
            <Text text="Biacore Intelligent Analysis™ Software – our machine learning software extension that helps to evaluate your data in minutes, not hours. Our machine learning models are pretrained by Cytiva scientists and engineers. Use them to analyze large data sets for critical applications in your low molecular weight workflow." />
          </li>
        </ol>
        <Spacer x={2} />
        {/* <Image
          images={[
            {
              src: image_1,
              alt: "Biacore insight Software image",
            },
            {
              src: image_2,
              alt: "Biacore insight Software image",
            },
          ]}
        />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: image_3,
              alt: "Biacore insight Software image",
            },
            {
              src: image_4,
              alt: "Biacore insight Software image",
            },
          ]}
        /> */}
      </div>
    </>
  );
};
