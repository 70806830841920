import { Title, Text, Image, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import sample_hotel_image from "src/assets/images/sample hotel.png";

export const MethodTools: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Operational efficiency may be significantly improved by adding ligand attachment (immobilization) methods, analysis methods, cleaning procedures, temperature changes, and other relevant steps to the Activity queue in a fully flexible manner, minimizing waiting time" />
        <Spacer />
        <Text text="Load sample and reagents to the sample hotel at any time, except when the sample tray is transferred between the sample hotel and sample compartment." />
        <Spacer />
        <Image
          images={[
            {
              src: sample_hotel_image,
              alt: "Sample hotel image",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
