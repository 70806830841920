import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";

import { SyringePumps } from "./contents/precisionSensitivity/SyringePumps";
import { BufferInlets } from "./contents/precisionSensitivity/BufferInlets";
import { Microfluidics } from "./contents/precisionSensitivity/Microfluidics";
import { TemperatureControl } from "./contents/precisionSensitivity/TemperatureControl";
import { SampleInjectionTools } from "./contents/increasedCapacity/SampleInjectionTools";
import { MethodTools } from "./contents/increasedCapacity/MethodTools";
import { FlowCells } from "./contents/increasedCapacity/FlowCells";
import { Scalable } from "./contents/increasedCapacity/Scalable";
import { SampleHotel } from "./contents/increasedCapacity/SampleHotel";
import { ExpandAffinity } from "./contents/expandAffinity/ExpandAffinity";
import { Reproducibility } from "./contents/reproducibility/Reproducibility";
import { BiacoreInsightSoftware } from "./contents/easeOfUse/BiacoreInsightSoftware";
import { TrainingTools } from "./contents/easeOfUse/TrainingTools";

const Content: FC = (): JSX.Element => {
  const { activeSubLink } = useStore();
  switch (activeSubLink.id) {
    case SubLinkId.microfluidics:
      return <Microfluidics />;
    case SubLinkId.syringePumps:
      return <SyringePumps />;
    case SubLinkId.bufferInlets:
      return <BufferInlets />;
    case SubLinkId.tempratureControl:
      return <TemperatureControl />;
    case SubLinkId.reproducibility:
      return <Reproducibility />;
    case SubLinkId.flowCells:
      return <FlowCells />;
    case SubLinkId.sampleInjectionTools:
      return <SampleInjectionTools />;
    case SubLinkId.methodTools:
      return <MethodTools />;
    case SubLinkId.sampleHotel:
      return <SampleHotel />;
    case SubLinkId.scalable:
      return <Scalable />;
    case SubLinkId.expandBeyondAffinity:
      return <ExpandAffinity />;
    case SubLinkId.biacoreInsightSoftware:
      return <BiacoreInsightSoftware />;
    case SubLinkId.trainingTools:
      return <TrainingTools />;
    default:
      return <></>;
  }
};

const SidebarContent: FC = (): JSX.Element => {
  return (
    <div className="c-sidebar__wrapper">
      <Content />
    </div>
  );
};

export default SidebarContent;
