import { CustomLink } from "../types";

export enum SubLinkId {
  microfluidics = "1-1",
  syringePumps = "1-2",
  bufferInlets = "1-3",
  tempratureControl = "1-4",
  reproducibility = "2",
  flowCells = "3-1",
  sampleInjectionTools = "3-2",
  methodTools = "3-3",
  sampleHotel = "3-4",
  scalable = "3-5",
  expandBeyondAffinity = "4",
  biacoreInsightSoftware = "5-1",
  trainingTools = "5-2",
}

export const links: CustomLink[] = [
  {
    id: "1",
    title: "Precision through sensitivity",
    hasSublinks: true,
    subLinks: [
      {
        id: "1-1",
        text: "Microfluidics",
        annotations: ["analysis_center"],
        changeMaterials: ["_HIDE_2"],
        animations: ["Scene"],
        animationSequences: [98, 103],
      },
      {
        id: "1-2",
        text: "Syringe pumps",
        annotations: ["pumps"],
        changeMaterials: ["_HIDE_2"],
        animations: ["Scene"],
        animationSequences: [86.5, 95],
      },
      { id: "1-3", text: "Buffer inlets", annotations: ["side-ports"] },
      { id: "1-4", text: "Temperature control" },
    ],
  },
  {
    id: "2",
    title: "Reproducibility",
    hasSublinks: false,
    subLinks: [
      {
        id: "2",
        text: "Reproducibility",
        annotations: ["chip-hatch"],
        animations: ["Scene"],
        animationSequences: [4.3, 10],
      },
    ],
  },
  {
    id: "3",
    title: "Increased capacity",
    hasSublinks: true,
    subLinks: [
      { id: "3-1", text: "Flow cells" },
      {
        id: "3-2",
        text: "Sample injection tools",
        annotations: ["R_window"],
        changeMaterials: [
          "UNO_label_1Splus",
          "UNO_label_1Kplus",
          "UNO_label_1K",
          "UNO_button_FLASH",
        ],
        animations: ["Scene"],
        animationSequences: [28.5, 43.5, 65, 78.5],
      },
      { id: "3-3", text: "Method tools" },
      {
        id: "3-4",
        text: "Sample hotel",
        annotations: ["extras", "door", "L_window"],
        changeMaterials: [
          "UNO_label_1Splus",
          "UNO_label_1Kplus",
          "UNO_label_1K",
          "UNO_button_FLASH",
        ],
        animations: ["Scene"],
        animationSequences: [12.8, 25, 51, 61],
      },
      {
        id: "3-5",
        text: "Scalable",
        changeMaterials: [
          "UNO_label_1K",
          "UNO_label_1Kplus",
          "UNO_label_1Splus",
        ],
      },
    ],
  },
  {
    id: "4",
    title: "Expand beyond affinity",
    hasSublinks: false,
    subLinks: [{ id: "4", text: "Expand beyond affinity" }],
  },
  {
    id: "5",
    title: "Ease of use",
    hasSublinks: true,
    subLinks: [
      { id: "5-1", text: "Biacore™ Insight Software" },
      { id: "5-2", text: "Training tools" },
    ],
  },
];
