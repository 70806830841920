import React, { useEffect, useRef } from "react";
import { useStore } from "src/store";
import {
  Title,
  Text,
  Spacer,
  Svg,
  Image,
  IframeVideo,
  CustomLink,
} from "../dynamic.components";
import microfluidics_image from "src/assets/images/microfluidics.png";
import { SidebarVisibility } from "src/types/index.d";
import { playAnimation } from "src/services/animations";
import { changeGroupMaterialsOpacity } from "src/services/materials-textures";

export const Microfluidics: React.FC = (): JSX.Element => {
  const videoRef = useRef<any>(null);
  const { text } = useStore().activeSubLink;
  const {
    apiref,
    sidebarStatus,
    animations,
    animationStopped,
    setAnimationStopped,
    activeSubLink,
  } = useStore();

  if (videoRef.current) {
    videoRef.current.style.setProperty(
      "--video-height",
      `${videoRef.current.clientWidth * 0.5625}px`
    );
  }

  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "analysis_center"
    ) {
      activeSubLink.changeMaterials &&
        changeGroupMaterialsOpacity(activeSubLink.changeMaterials[0], 0.1);
      playAnimation();
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "analysis_center"
    ) {
      activeSubLink.changeMaterials &&
        changeGroupMaterialsOpacity(activeSubLink.changeMaterials[0], 1);
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Microfluidics are designed for precise control of sample volumes, without diffusion between sample and buffer while in the fluidic channels. This is true for pure samples as well as complex cell cultures, serum, or plasma samples." />
        <Spacer />
        <Text text="Resources:" />
        <ul className="c-sidebar__list">
          <li className="c-sidebar__list-item c-sidebar__text">
            Principles of SPR (new in progress)
          </li>
          <Spacer />
          <div ref={videoRef}>
            <IframeVideo src="https://www.youtube.com/embed/nkcC9BLmlrA" />
          </div>
          <Spacer />
          <li className="c-sidebar__list-item c-sidebar__text">
            Surface Plasmon Resonance (SPR) ― Visualize your research{" "}
            <CustomLink
              href="https://cdn.cytivalifesciences.com/api/public/content/NLD56Lz7HEqABel8-awmjg-pdf"
              text="Download PDF"
              inline={true}
            />
          </li>
        </ul>
        <Spacer x={2} />
        <Image
          images={[
            {
              src: microfluidics_image,
              alt: "Microfluidics",
            },
          ]}
        />
      </div>
    </>
  );
};
