import React from "react";
import { cameraMoveTiming, multiModelComponentId } from "src/constants";
import { switchToModel } from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "src/store";
import { SubLink, TreeDModel, SidebarVisibility } from "src/types/index.d";

type SubLinkProps = {
  subLink: SubLink;
};

const SubLinkComponent: React.FC<SubLinkProps> = ({ subLink }) => {
  const { setActiveSubLink, setSidebarStatus, changeModel } = useStore();

  const delayToOpenSidebar =
    subLink.annotations && !multiModelComponentId.includes(subLink.id)
      ? cameraMoveTiming * 1000
      : 0;

  const onSubLinkClick = () => {
    setActiveSubLink(subLink);
    if (subLink.annotations && !multiModelComponentId.includes(subLink.id)) {
      playHotspot(subLink.annotations[0]);
    }
    if (multiModelComponentId.includes(subLink.id)) {
      changeModel(TreeDModel.one_S_Plus);
    }

    if (subLink.id === "3-5") {
      changeModel(TreeDModel.one_K);
      switchToModel(TreeDModel.one_K);
    }
    setTimeout(() => {
      setSidebarStatus(SidebarVisibility.show);
    }, delayToOpenSidebar);
  };

  return (
    <li className="c-cytiva__sub-list__item">
      <button
        className="button--fixwidth button button--primary button--large button--inline"
        onClick={onSubLinkClick}
      >
        {subLink.text}
      </button>
    </li>
  );
};

export default SubLinkComponent;
