import React from "react";
import { Title, Text, Image, Spacer, CustomLink } from "../dynamic.components";
import { useStore } from "src/store";

export const TrainingTools: React.FC = () => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text
          text="With our library of application guides and lab procedures, you can add free online learning courses that help you to sharpen your skills in SPR analysis. Learn more about SPR technology, how to get started, how to design your assay, evaluate your data and how to work with and use Biacore™ systems.
          Our experienced application scientists can guide you through your SPR journey and organize and facilitate local courses (classroom or virtual)."
        />
        <Spacer x={2} />
        <Text text="Resources:" />
        <Spacer />
        <ul className="c-sidebar__list" style={{ listStyle: "none" }}>
          <li className="c-sidebar__list-item c-sidebar__text">
            <CustomLink
              href="https://www.cytivalifesciences.com/education/online-learning/courses?sort=name&phrase=biacore&type=all"
              text="Online courses"
            />
          </li>
          <Spacer />

          <li className="c-sidebar__list-item c-sidebar__text">
            <CustomLink
              href=" https://www.cytivalifesciences.com/solutions/protein-research/Interaction-analysis-with-Biacore-surface-plasmon-resonance-SPR/Get-started-with-surface-plasmon-resonance-SPR-interaction-analysis#Applicationguideshandbook"
              text="Application guides and handbooks"
            />
          </li>
          <Spacer />

          <li className="c-sidebar__list-item c-sidebar__text">
            <CustomLink
              href="https://www.cytivalifesciences.com/solutions/protein-research/Interaction-analysis-with-Biacore-surface-plasmon-resonance-SPR/Get-started-with-surface-plasmon-resonance-SPR-interaction-analysis#labprocedures"
              text="Lab procedures"
            />
          </li>
          <Spacer />

          <li className="c-sidebar__list-item c-sidebar__text">
            <CustomLink
              href="https://www.cytivalifesciences.com/support/online-tools/Biacore-surface-plasmon-resonance"
              text="Calculators and tools"
            />
          </li>
        </ul>
      </div>
    </>
  );
};
