import React from "react";
import { useStore } from "src/store";
import { Title, Text, Image, Spacer } from "../dynamic.components";
import biacore1k_image from "src/assets/images/biacore1k3.png";
import { TreeDModel } from "src/types/index.d";
import { switchToModel } from "src/services/animations";

export const Scalable: React.FC = (): JSX.Element => {
  const { activeSubLink, activeModel, changeModel } = useStore();

  return (
    <>
      <Title title={activeSubLink.text} />
      <div className="c-sidebar__body">
        <Text text="When you need to increase flexibility and capacity — Biacore™ 1K can be upgraded in the field to Biacore™ 1K+. The upgrades include a buffer selector and an additional sample tray. The additional sample tray gives you a 100% increase in sample capacity — there is now room for two microplates and reagent racks. The upgrade also gives you full flexibility in addressing flow cells." />
        <Image
          images={[
            {
              src: biacore1k_image,
              alt: "Biacore1k image",
              customWidth: "90%",
            },
          ]}
        />
        <Spacer x={2} />
        <button
          onClick={() => {
            if (activeModel !== TreeDModel.one_K) {
              changeModel(TreeDModel.one_K);
              switchToModel(TreeDModel.one_K);
            }
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K && "button--hover"
          }`}
        >
          Biacore™&nbsp;1K
        </button>
        <Spacer />
        <button
          onClick={() => {
            if (activeModel !== TreeDModel.one_K_Plus) {
              changeModel(TreeDModel.one_K_Plus);
              switchToModel(TreeDModel.one_K_Plus);
            }
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K_Plus && "button--hover"
          }`}
        >
          Biacore™&nbsp;1K+
        </button>
      </div>
    </>
  );
};
