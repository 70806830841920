import { useStore } from "../store";
import { Material, LampName } from "../types";
import { getGroupMaterials, getMaterial, getMaterialByName } from "./materials";

export const changeGroupMaterialsOpacity = (text: string, opacity: number) => {
  const transition = true;
  const materials = getGroupMaterials(text);

  if (transition) {
    let timeInterval = 50;
    const identifier = opacity < 0.5 ? -1 : 1;
    let currentOpacity = opacity < 0.5 ? 1 : 0.1;
    let opacityInterval = 0.2;
    const subscriber = setInterval(() => {
      const newOpacity = currentOpacity + opacityInterval * identifier;
      materials.forEach((mat) => {
        changeMaterialOpacity(mat, newOpacity);
      });
      opacityInterval = 0.1 + opacityInterval;
      if (
        (opacity < 0.5 && newOpacity <= 0.15) ||
        (opacity > 0.5 && newOpacity >= 0.85)
      ) {
        clearInterval(subscriber);
        materials.forEach((mat) => {
          changeMaterialOpacity(mat, opacity);
        });
      }
    }, timeInterval);
  } else {
  }
};

export const changeBufferManagementMaterials = (visibility: string) => {
  const buffer_management_mat = getMaterial(0);
  const a_pubm_mat = getMaterial(1);
  const b_pubm_mat = getMaterial(2);
  const c_label_pubm_mat = getMaterial(3);
  const plastic_black_mat = getMaterial(4);
  const plastic_white_pubm_mat = getMaterial(5);

  const opacity = visibility === "show" ? 1 : 0;
  const visibe = visibility === "show" ? true : false;

  if (
    buffer_management_mat &&
    a_pubm_mat &&
    b_pubm_mat &&
    c_label_pubm_mat &&
    plastic_black_mat &&
    plastic_white_pubm_mat
  ) {
    changeMaterialVisibility(buffer_management_mat, visibe);
    changeMaterialOpacity(a_pubm_mat, opacity);
    changeMaterialOpacity(b_pubm_mat, opacity);
    changeMaterialOpacity(c_label_pubm_mat, opacity);
    changeMaterialOpacity(plastic_black_mat, opacity);
    changeMaterialOpacity(plastic_white_pubm_mat, opacity);
  }
};

export const changeMaterialOpacity = (material: Material, opacity: number) => {
  const api = useStore.getState().apiref;
  let updatedMaterial = material;
  updatedMaterial = {
    ...material,
    channels: {
      ...material.channels,
      Opacity: { ...material.channels.Opacity, factor: opacity },
    },
  };
  api.setMaterial(updatedMaterial);
};

export const changeMaterialOpacityByName = (
  matName: string,
  opacity: number
) => {
  const api = useStore.getState().apiref;
  const material = getMaterialByName(matName);
  let updatedMaterial = material;
  if (material) {
    updatedMaterial = {
      ...material,
      channels: {
        ...material.channels,
        Opacity: {
          ...material.channels.Opacity,
          factor: opacity,
          enable: true,
        },
      },
    };
    api.setMaterial(updatedMaterial);
  }
};

export const changeMaterialVisibility = (
  material: Material,
  visible: boolean
) => {
  const api = useStore.getState().apiref;
  let updatedMaterial = material;
  const visibleState = {
    emitFactor: 1,
    ior: 1.008,
    reflectionColor: [0, 0.24228, 0.15896],
  };

  updatedMaterial = visible
    ? {
        ...material,
        channels: {
          ...material.channels,
          Opacity: {
            ...material.channels.Opacity,
            ior: visibleState.ior,
            refractionColor: visibleState.reflectionColor,
          },
          EmitColor: {
            ...material.channels.EmitColor,
            factor: visibleState.emitFactor,
          },
        },
      }
    : material;
  api.setMaterial(updatedMaterial);
};

export const changeMaterialEmissionFactor = (
  material: Material,
  factor: number
) => {
  const api = useStore.getState().apiref;
  let updatedMaterial = material;
  updatedMaterial = {
    ...material,
    channels: {
      ...material.channels,
      EmitColor: { ...material.channels.EmitColor, factor },
    },
  };
  api.setMaterial(updatedMaterial);
};

export const addVideoTexture = (videoUrl: string, material: Material) => {
  const api = useStore.getState().apiref;

  api.addVideoTexture(
    videoUrl,
    {
      loop: false,
      mute: true,
    },
    function (err: any, textureUid: string) {
      let screen = material;
      if (!err) {
        screen = {
          ...screen,
          channels: {
            ...screen.channels,
            EmitColor: {
              ...screen.channels.EmitColor,
              enable: true,
              factor: 1,
              texture: {
                ...screen.channels.EmitColor.texture,
                uid: textureUid,
              },
            },
            DiffuseColor: {
              ...screen.channels.DiffuseColor,
              texture: {
                ...screen.channels.DiffuseColor.texture,
                uid: textureUid,
              },
            },
          },
        };
        api.setMaterial(screen);
      }
    }
  );
};

export const removeVideoTexture = (material: Material) => {
  const api = useStore.getState().apiref;
  api.setMaterial(material);
};

export const switchLamp = (lampName: LampName) => {
  const api = useStore.getState().apiref;
  const materials = useStore.getState().materials;
  const lampStatus = useStore.getState().stackLights[lampName];
  const toggleLamp = lampStatus ? 5 : 0;

  let lamp = materials.find(
    (material: Material) => material.name === lampName
  ) as Material;
  lamp = {
    ...lamp,
    channels: {
      ...lamp.channels,
      EmitColor: { ...lamp.channels.EmitColor, factor: toggleLamp },
    },
  };
  api.setMaterial(lamp);
};

export const showOneSPlusLabel = () => {
  changeMaterialOpacityByName("UNO_label_1Kplus", 0);
  changeMaterialOpacityByName("UNO_label_1Splus", 1);
};
