import React from "react";
import { Title, Text, Spacer, Image, Subtitle } from "../dynamic.components";
import flow_cell_1_image from "src/assets/images/flow-cell-1.png";
import flow_cell_2_image from "src/assets/images/flow-cell-2.png";
import { useStore } from "src/store";

export const FlowCells: React.FC = (): JSX.Element => {
  const { text } = useStore().activeSubLink;
  return (
    <>
      <Title title={text} />
      <div className="c-sidebar__body">
        <Text text="Biacore™&nbsp;1 series introduces one channel instruments that consists of six flow cells arranged in series." />

        <Spacer />
        <Image
          images={[
            {
              src: flow_cell_1_image,
              alt: "flow-cell-1 image",
            },
            {
              src: flow_cell_2_image,
              alt: "flow-cell-2 image",
            },
          ]}
        />
        <Spacer />
        <Text
          text="Utilizing the six flow cells during the assay design with the visually improved and easy to use Method builder, or the new, Interactive run, 
      is more cost effective, increases the analytical flexibility, and shortens time in assay development."
        />
        <Spacer />
        <Text text="The six flow cells in Biacore™&nbsp;1K can be addressed individually as single flow cells (1, 2, 3, 4, 5, 6) and in pairs (1/2, 3/4, 5/6)." />
        <Spacer />
        <Text text="Biacore™&nbsp;1K+ and Biacore™&nbsp;1S+ have the additional option to address the flow cells in quadruplets (1234, 3456) or all together in sequence (123456)." />
        <Spacer x={2} />
        <div className="c-sidebar__table-wrapper">
          <table className="c-sidebar__table">
            <thead>
              <tr>
                <th></th>
                <th>Biacore™&nbsp;1K</th>
                <th>Biacore™&nbsp;1K+</th>
                <th>Biacore™&nbsp;1S+</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Addressing of six flow cells</th>
                <td>Individually</td>
                <td>Individually</td>
                <td>Individually</td>
              </tr>
              <tr>
                <th></th>
                <td>Pairs</td>
                <td>Pairs</td>
                <td>Pairs</td>
              </tr>
              <tr>
                <th></th>
                <td></td>
                <td>Quadruplets</td>
                <td>Quadruplets</td>
              </tr>
              <tr>
                <th></th>
                <td></td>
                <td>Sequence</td>
                <td>Sequence</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
