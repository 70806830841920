import { useStore } from "../store";
import { Material, LampName } from "../types";

export const storeMaterials = () => {
  const api = useStore.getState().apiref;
  api.getMaterialList((err: any, materials: Material[]) => {
    if (!err) {
      useStore.setState({ materials: materials });
    }
  });
};

export const getMaterial = (index: number) => {
  const { activeSubLink, materials } = useStore.getState();

  return (
    activeSubLink.changeMaterials &&
    materials.find((mat: Material) => {
      if (activeSubLink.changeMaterials) {
        return mat.name === activeSubLink.changeMaterials[index];
      }
    })
  );
};

export const getMaterialByName = (name: string) => {
  const { materials } = useStore.getState();

  return materials.find((mat: Material) => mat.name === name);
};

export const getGroupMaterials = (text: string) => {
  const { materials } = useStore.getState();
  return materials.filter((mat) => mat.name.includes(text));
};

export const switchLamp = (lampName: string) => {
  const api = useStore.getState().apiref;
  api.current.getMaterialList((err: any, materials: any) => {
    const lamp = materials.find((material: any) => material.name === lampName);
    lamp.channels.EmitColor.factor = 5;
    api.current.setMaterial(lamp, () => {
      // console.log(`${lampName} is turned on`, lamp);
    });
  });
};
