import { Title, Text, Image, Spacer } from "../dynamic.components";
import { useStore } from "src/store";
import dual_image from "src/assets/images/dual command.png";
import aba_image from "src/assets/images/aba command.png";
import poly_command_1_image from "src/assets/images/poly command 1.png";
import poly_command_2_image from "src/assets/images/poly command 2.png";
import poly_command_3_image from "src/assets/images/poly command 3.png";
import { SidebarVisibility, TreeDModel } from "src/types/index.d";
import { playHotspot } from "src/services/annotations";
import { resetCameraAnnotation } from "src/constants";
import { changeMaterialOpacityByName } from "src/services/materials-textures";
import { useEffect } from "react";
import { playAnimationByModel } from "src/services/animations";

export const SampleInjectionTools: React.FC = (): JSX.Element => {
  const {
    activeSubLink,
    changeModel,
    activeModel,
    animationIsPlaying,
    sidebarStatus,
    animationStopped,
    apiref,
    animations,
    setAnimationStopped,
  } = useStore();

  const showBiacoreOneSPlusLabel = () => {
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[1], 0);
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[0], 1);
  };

  const showBiacoreOneKPlusLabel = () => {
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[0], 0);
    activeSubLink.changeMaterials &&
      changeMaterialOpacityByName(activeSubLink.changeMaterials[1], 1);
  };

  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "R_window"
    ) {
      playAnimationByModel(activeModel);
    }
    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotations &&
      activeSubLink.annotations[0] === "R_window"
    ) {
      setAnimationStopped(true);
      showBiacoreOneSPlusLabel();
    }
  }, [sidebarStatus, animationStopped]);

  useEffect(() => {
    playAnimationByModel(activeModel);
  }, [activeModel]);

  return (
    <>
      <Title title={activeSubLink.text} />
      <div className="c-sidebar__body">
        <Text text="In addition to the standard process for sample injection, our innovative injection commands Dual, ABA, and Poly facilitate samples to be combined without dispersion and support the set-up of competition assays or multi-complex binding analysis." />
        <Spacer />
        <ul className="c-sidebar__list">
          <li className="c-sidebar__list-item c-sidebar__text">
            The <strong>Dual</strong> command injects two solutions in sequence
            with no intermediate washing steps.
            <Spacer />
            <Image
              images={[
                {
                  src: dual_image,
                  alt: "Dual command image",
                  customWidth: "100%",
                },
              ]}
            />
          </li>
          <li className="c-sidebar__list-item c-sidebar__text">
            The <strong>ABA</strong> command allows two different solutions to
            be injected over the surface in the same cycle in the following
            order: solution A, solution B, then solution A.
            <Spacer />
            <Image
              images={[
                {
                  src: aba_image,
                  alt: "ABA command image",
                  customWidth: "100%",
                },
              ]}
            />
          </li>
          <li className="c-sidebar__list-item c-sidebar__text">
            The <strong>Poly</strong> command enables the injection of three to
            five solutions in sequence with no intermediate washing steps
            (Solution A - E).
            <Spacer />
            <Image
              images={[
                {
                  src: poly_command_1_image,
                  alt: "poly command 1 image",
                  customWidth: "100%",
                },
              ]}
            />
            <Image
              images={[
                {
                  src: poly_command_2_image,
                  alt: "poly command 2 image",
                  customWidth: "100%",
                },
              ]}
            />
            <Image
              images={[
                {
                  src: poly_command_3_image,
                  alt: "poly command 3 image",
                  customWidth: "100%",
                },
              ]}
            />
          </li>
        </ul>
        <Spacer x={3} />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_S_Plus);
            playHotspot(resetCameraAnnotation);
            showBiacoreOneSPlusLabel();
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_S_Plus && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1S+
        </button>
        <Spacer />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_K_Plus);
            playHotspot(resetCameraAnnotation);
            showBiacoreOneKPlusLabel();
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K_Plus && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1K+
        </button>
        <Spacer />
        <button
          onClick={() => {
            changeModel(TreeDModel.one_K);
            playHotspot(resetCameraAnnotation);
          }}
          className={`button button--secondary button--xlarge button--fixwidth ${
            activeModel === TreeDModel.one_K && "button--hover"
          }`}
          disabled={animationIsPlaying}
        >
          Biacore™&nbsp;1K
        </button>
      </div>
    </>
  );
};
